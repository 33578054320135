export const changeRoute = (step, history) => {
    const stepsArray = [
        "first",
        "second",
        "third",
        "fourth",
        "fifth",
        "sixth",
        "seventh",
        "eighth",
        "results",
    ];
    const valuesArray = stepsArray.map(
        (el) => localStorage.getItem(el) || null
    );
    if (valuesArray.slice(0, step - 1).includes(null)) {
        localStorage.clear();
        history.push("/main");
        return;
    } else {
        return;
    }
};
