import "./Chart.scss";
import React, { useEffect, useState } from "react";
const Chart = ({ numbers }) => {
    let WW = window.innerWidth;
    const [strokesCoords, setCoords] = useState([]);
    const [showSvg, setShowSvg] = useState(false);
    useEffect(() => {
        const arr = [];
        for (let i = 0; i < 8; i++) {
            const top = WW > 1400 ? 117.5 : WW < 768 ? 53.5 : 147;
            let left = 0;
            if (WW > 1400) {
                left = Number(
                    `${i === 5 || i === 7 ? 69 : i === 6 ? 3 : 234.5}`
                );
            } else if (WW < 768) {
                switch (i) {
                    case 0:
                        left = 101;
                        break;
                    case 1:
                        left = 104;
                        break;
                    case 2:
                        left = 106;
                        break;
                    case 3:
                        left = 104;
                        break;
                    case 4:
                        left = 101;
                        break;
                    case 5:
                        left = 27;
                        break;
                    case 6:
                        left = 0;
                        break;
                    case 7:
                        left = 27;
                        break;
                    default:
                        break;
                }
            } else {
                switch (i) {
                    case 0:
                        left = 232;
                        break;
                    case 1:
                        left = 233;
                        break;
                    case 2:
                        left = 236;
                        break;
                    case 3:
                        left = 233;
                        break;
                    case 4:
                        left = 232;
                        break;
                    case 5:
                        left = 106;
                        break;
                    case 6:
                        left = 59;
                        break;
                    case 7:
                        left = 106;
                        break;
                    default:
                        break;
                }
            }
            arr.push({
                strokeTop:
                    document
                        .querySelector(`#last-${i + 1}`)
                        .getBoundingClientRect().top -
                    document
                        .querySelector(".circle-figure")
                        .getBoundingClientRect().top +
                    top,
                strokeLeft:
                    document
                        .querySelector(`#last-${i + 1}`)
                        .getBoundingClientRect().left -
                    document
                        .querySelector(`.stroke-${i + 1}`)
                        .getBoundingClientRect().left +
                    left,
            });
        }
        setCoords(arr);
        setTimeout(() => {
            setShowSvg(true);
        }, 500);
    }, []);

    return (
        <div className="circle-figure">
            <div className="center"></div>
            {numbers &&
                numbers.map((el, idx) => (
                    <div
                        className={`stroke-container stroke-${idx + 1}`}
                        key={idx}
                    >
                        {[0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((dot, dotId) => (
                            <>
                                <div
                                    className={`stroke${
                                        dotId < el && showSvg
                                            ? ` transition-stroke color-stroke--${idx +
                                                  1}`
                                            : ""
                                    }`}
                                    key={dotId + 20}
                                ></div>
                                <div
                                    className={`dot${
                                        dotId < el && showSvg
                                            ? ` transition-dot color-dot--${idx +
                                                  1}`
                                            : ""
                                    }`}
                                    id={
                                        dotId + 1 === el
                                            ? `last-${idx + 1}`
                                            : ""
                                    }
                                ></div>
                            </>
                        ))}
                    </div>
                ))}
            {strokesCoords &&
                strokesCoords.map((el, idx) => (
                    <svg className="svg" key={idx}>
                        {" "}
                        <line
                            id={`my-path-${idx + 1}`}
                            x1={el.strokeLeft}
                            y1={el.strokeTop}
                            x2={
                                idx === 7
                                    ? strokesCoords[0].strokeLeft
                                    : strokesCoords[idx + 1].strokeLeft
                            }
                            y2={
                                idx === 7
                                    ? strokesCoords[0].strokeTop
                                    : strokesCoords[idx + 1].strokeTop
                            }
                            className={`svg-${idx + 1}${
                                showSvg ? ` transition-svg-${idx + 1}` : ""
                            }`}
                        ></line>
                    </svg>
                ))}
        </div>
    );
};

export default Chart;
