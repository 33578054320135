import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import AppReducer from "./reduceApp";

import { reducer as formReducer } from "redux-form";

const rootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        app: AppReducer,
        form: formReducer,
    });

export default rootReducer;
