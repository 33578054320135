import { put, takeEvery, all } from "redux-saga/effects";
import { handleClientsActions } from "./clients";

export default function* rootSaga() {
    yield all([handleLoader(), handleSnack(), handleClientsActions()]);
}

const delay = ms => new Promise(res => setTimeout(res, ms));

//loader

function* handleLoader() {
    yield takeEvery("LOGIN", enableLoader);
    yield takeEvery("LOGIN_SUCCESS", disableLoader);
    yield takeEvery("LOGIN_FAIL", disableLoader);

    yield takeEvery("GET_RESOURCES", enableLoader);
    yield takeEvery("GET_RESOURCES_SUCCESS", disableLoader);
    yield takeEvery("GET_RESOURCES_FAIL", disableLoader);

    yield takeEvery("GET_PERSONAL_INFO", enableLoader);
    yield takeEvery("GET_LIFESTYLE_SUCCESS", disableLoader);
    yield takeEvery("GET_LIFESTYLE_FAIL", disableLoader);

    yield takeEvery("PATCH_PERSONAL_INFO", enableLoader);
    yield takeEvery("PATCH_PERSONAL_INFO_SUCCESS", disableLoader);
    yield takeEvery("PATCH_PERSONAL_INFO_FAIL", disableLoader);

    yield takeEvery("PATCH_ANTHROPOMETRIC_DATA", enableLoader);
    yield takeEvery("PATCH_ANTHROPOMETRIC_DATA_SUCCESS", disableLoader);
    yield takeEvery("PATCH_ANTHROPOMETRIC_DATA_FAIL", disableLoader);

    yield takeEvery("PATCH_LIFESTYLE", enableLoader);
    yield takeEvery("PATCH_LIFESTYLE_SUCCESS", disableLoader);
    yield takeEvery("PATCH_LIFESTYLE_FAIL", disableLoader);
}

function* enableLoader() {
    yield put({ type: "LOADING", payload: true });
}

function* disableLoader() {
    yield put({ type: "LOADING", payload: false });
}

//snacks

function* handleSnack() {
    yield takeEvery("ERROR_SNACK_CLOSE", clearSnackTexts);
}

function* openErrorSnack(e) {
    yield put({
        type: "ERROR_SNACK_OPEN",
        payload: e && e.error && e.error.response.data ? e.error.response.data.detail : "Something went wrong"
    });
}

function* openSuccessSnack(e) {
    yield put({ type: "SUCCESS_SNACK_OPEN", payload: e });
}

function* clearSnackTexts() {
    yield delay(1000);
    yield put({ type: "SNACK_TEXTS_CLEARING" });
}
