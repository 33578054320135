let BASE_URL, SECOND_URL;

if (
    window.location.host === "localhost:3000" ||
    window.location.host === "localhost:3001"
) {
    BASE_URL = "http://api.titova.4-com.pro/api/v0";
} else {
    BASE_URL = process.env.REACT_APP_API_URL;
}

SECOND_URL = "";

export const API_BASE_URL = BASE_URL;
export const API_SECOND_URL = SECOND_URL;
