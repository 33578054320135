import React, { Component } from "react";
import { connect } from "react-redux";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import RenderField from "../HelperComponents/RenderField/RenderField";
import Loader from "../HelperComponents/LoaderMini/Loader";
import Fade from "react-reveal/Fade";
import { Field, reduxForm } from "redux-form";

import logo from "../../assets/image/logotype.svg";
import { Link } from "react-router-dom";
import "./MailPage.scss";
import { postStep } from "./../../actions/main";
import { changeRoute } from "./../../helpers/functions";

class MailPage extends Component {
    state = {
        loading: false,
        values: [],
    };

    componentDidMount() {
        const { history } = this.props;
        changeRoute(9, history);
        const stepsArray = [
            "first",
            "second",
            "third",
            "fourth",
            "fifth",
            "sixth",
            "seventh",
            "eighth",
        ];
        this.setState({
            values: stepsArray.map((el) => +localStorage.getItem(el) || null),
        });
    }

    submitForm = (data) => {
        const { postStep, history } = this.props;
        const { values } = this.state;
        this.setState({ loading: true });
        const obj = {
            love: values[0],
            selfishness: values[1],
            ability_negotiate: values[2],
            here_and_now: values[3],
            relationship_with_dad: values[4],
            your_freedom: values[5],
            flexibility: values[6],
            self_confidence: values[7],
        };

        if (data.name) {
            obj.instagram = data.name;
        }
        if (data.email) {
            obj.email = data.email;
        }

        postStep(obj).then((res) => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 201
            ) {
                history.push("/main/chart");
            } else {
                this.setState({ loading: false });
            }
        });
    };

    render() {
        const { handleSubmit, submitting, pristine, valid } = this.props;
        const { loading } = this.state;

        return (
            <TransitionedBlock>
                <div className="mail_page">
                    <div className="head">
                        <Link to="/main/first-step" className="pulse">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>
                    <div className="shadow" />
                    <Fade delay={600}>
                        <div className="wrapper_form">
                            <div className="title">Последний шаг</div>
                            <div className="descriptions">
                                Введи свой никнейм в Instagram и почту, <br />
                                чтобы увидеть колесо отношений
                            </div>

                            <form onSubmit={handleSubmit(this.submitForm)}>
                                <div className="block_field">
                                    <p>Никнейм в Instagram</p>
                                    <Field
                                        name="name"
                                        type="text"
                                        component={RenderField}
                                        placeholder="@example"
                                    />
                                </div>
                                <div className="block_field">
                                    <p>Почта</p>
                                    <Field
                                        name="email"
                                        type="text"
                                        component={RenderField}
                                        placeholder="example@gmail.com"
                                    />
                                </div>
                                <hr />
                                <div className="wrapper_btn">
                                    <button
                                        className="pulse"
                                        type="submit"
                                        disabled={
                                            submitting || pristine || !valid
                                        }
                                    >
                                        {loading ? (
                                            <Loader
                                                height={35}
                                                width={2}
                                                margin="2px"
                                                color={"#912F40"}
                                            />
                                        ) : (
                                            "Далее"
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Fade>
                    <div className="bg" />
                </div>
            </TransitionedBlock>
        );
    }
}
export const validate = (values) => {
    const errors = {};
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)
    ) {
        errors.email = "Неправильный email";
    }
    return errors;
};

const MailPageForm = reduxForm({
    form: "MailPage",
    validate,
})(MailPage);

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = { postStep };

export default connect(mapStateToProps, mapDispatchToProps)(MailPageForm);
