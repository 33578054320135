import React, { Component } from "react";
import { connect } from "react-redux";
import Fade from "react-reveal/Fade";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import { Link } from "react-router-dom";
import logo from "../../assets/image/logotype.svg";
import love from "../../assets/image/love.png";
import flexible from "../../assets/image/flexible.png";
import compromise from "../../assets/image/compromise.png";
import selfishness from "../../assets/image/selfishness.png";
import with_dad from "../../assets/image/with_dad.png";
import freedom from "../../assets/image/freedom.png";
import self_confidence from "../../assets/image/self_confidence.png";
import now from "../../assets/image/here&now.png";
import "./ChartPage.scss";
import { changeRoute } from "./../../helpers/functions";
import Chart from "./Chart/Chart";
import { postStep } from "./../../actions/main";

class ChartPage extends Component {
    state = {
        open: false,
        values: [],
    };
    componentDidMount() {
        const { history, postStep } = this.props;

        changeRoute(9, history);
        const stepsArray = [
            "first",
            "second",
            "third",
            "fourth",
            "fifth",
            "sixth",
            "seventh",
            "eighth",
        ];
        const values = stepsArray.map(
            (el) => +localStorage.getItem(el) || null
        );
        this.setState({
            values: stepsArray.map((el) => +localStorage.getItem(el) || null),
        });
        localStorage.clear();
        const obj = {
            love: values[0],
            selfishness: values[1],
            ability_negotiate: values[2],
            here_and_now: values[3],
            relationship_with_dad: values[4],
            your_freedom: values[5],
            flexibility: values[6],
            self_confidence: values[7],
        };
        postStep(obj);
        setTimeout(() => {
            this.setState({ open: true });
        }, 12000);
    }
    toggleDialog = (e) => {
        this.setState(({ open }) => ({
            open: !open,
        }));
    };
    render() {
        const { open, values } = this.state;
        return (
            <TransitionedBlock>
                <div className="chart_page">
                    <Fade delay={300}>
                        <div className="head">
                            <Link to="/main/first-step" className="pulse">
                                <img src={logo} alt="logo" />
                            </Link>
                        </div>
                    </Fade>

                    <Fade delay={600}>
                        <div className="text">Колесо отношений</div>
                    </Fade>
                    <Fade delay={900}>
                        <div className="chart-wrapper">
                            {values && values.length > 0 && (
                                <Chart numbers={values} />
                            )}
                            <div className="chart">
                                <div className="chart_block_wrapper">
                                    <Fade delay={1000}>
                                        <div className="chart_block">
                                            <img src={love} alt="love" />{" "}
                                            <span>Любовь</span>
                                        </div>
                                    </Fade>
                                    <Fade delay={1200}>
                                        <div className="chart_block">
                                            <img
                                                src={self_confidence}
                                                alt="self_confidence"
                                            />{" "}
                                            <span>Уверенность в себе</span>
                                        </div>
                                    </Fade>
                                    <Fade delay={1400}>
                                        <div className="chart_block">
                                            <img
                                                src={compromise}
                                                alt="compromise"
                                            />{" "}
                                            <span>Умение договариваться</span>
                                        </div>
                                    </Fade>
                                    <Fade delay={1600}>
                                        <div className="chart_block">
                                            <img
                                                src={with_dad}
                                                alt="with_dad"
                                            />{" "}
                                            <span>Отношения с папой</span>
                                        </div>
                                    </Fade>
                                    <Fade delay={1800}>
                                        <div className="chart_block">
                                            <img
                                                src={flexible}
                                                alt="flexible"
                                            />{" "}
                                            <span>Гибкость</span>
                                        </div>
                                    </Fade>
                                    <Fade delay={2000}>
                                        <div className="chart_block">
                                            <img src={freedom} alt="freedom" />{" "}
                                            <span>Свобода</span>
                                        </div>
                                    </Fade>
                                    <Fade delay={2200}>
                                        <div className="chart_block">
                                            <img
                                                src={selfishness}
                                                alt="selfishness"
                                            />{" "}
                                            <span>Эгоизм</span>
                                        </div>
                                    </Fade>
                                    <Fade delay={2400}>
                                        <div className="chart_block">
                                            <img src={now} alt="now" />{" "}
                                            <span>Здесь и сейчас</span>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                    </Fade>
                    <Fade delay={2800}>
                        <div className="mob_info_chart">
                            <div>
                                <div>
                                    <img src={love} alt="love" />- Любовь
                                </div>
                                <div>
                                    <img
                                        src={self_confidence}
                                        alt="self_confidence"
                                    />
                                    - Уверенность в себе
                                </div>
                                <div>
                                    <img src={compromise} alt="compromise" />-
                                    Умение договариваться
                                </div>
                                <div>
                                    <img src={with_dad} alt="with_dad" />-
                                    Отношения с папой
                                </div>
                            </div>
                            <div>
                                <div>
                                    <img src={flexible} alt="flexible" />-
                                    Гибкость
                                </div>
                                <div>
                                    <img src={freedom} alt="freedom" />- Свобода
                                </div>
                                <div>
                                    <img src={selfishness} alt="selfishness" />-
                                    Эгоизм
                                </div>
                                <div>
                                    <img src={now} alt="now" />- Здесь и сейчас
                                </div>
                            </div>
                        </div>
                    </Fade>
                    <div className="bg" />
                    <DialogComponent open={open} onClose={this.toggleDialog}>
                        <div className="dialog">
                            <div className="dialog_title">Присоединяйся!</div>
                            <div className="dialog_text">
                                Если ты хочешь больше интересных тестов, идей и
                                стать участником растущего сообщества, то
                                подписывайся на{" "}
                                <a
                                    href="https://www.instagram.com/anastasia_officially_/"
                                    target="_blank"
                                >
                                    мой Instagram
                                </a>{" "}
                                и присоединяйся к{" "}
                                <a
                                    href="https://t.me/man_million"
                                    target="_blank"
                                >
                                    моей группе в Telegram
                                </a>
                            </div>
                        </div>
                    </DialogComponent>
                </div>
            </TransitionedBlock>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = { postStep };

export default connect(mapStateToProps, mapDispatchToProps)(ChartPage);
