import React from "react";
import App from "../containers/App";
import { Route, Switch, Redirect } from "react-router-dom";
import Container from "../containers/Container/Container";

export default (
    <App>
        <Switch>
            <Route path="/" exact render={() => <Redirect to="/main" />} />
            <Route path="/main" component={Container} />
            <Route render={() => <p>Not found</p>} />
        </Switch>
    </App>
);
