import React, { Component } from "react";
import { connect } from "react-redux";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import Slider from "react-rangeslider";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import "react-rangeslider/lib/index.css";
import logo from "../../assets/image/logotype.svg";
import self_confidence from "../../assets/image/self_confidence.png";
import { changeRoute } from "./../../helpers/functions";
import LoaderPage from "../HelperComponents/LoaderPage/Loader";

import "./Steps.scss";

class Eighth extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            value: 1,
            loading: false,
        };
    }
    componentDidMount() {
        const { history } = this.props;
        changeRoute(8, history);
    }
    handleChange = (value) => {
        this.setState({
            value: value,
        });
    };
    render() {
        const { value, loading } = this.state;
        const { history } = this.props;
        return (
            <TransitionedBlock>
                <div className="page_steps">
                    <Fade delay={300}>
                        <div className="head">
                            <Link to="/main/first-step" className="pulse">
                                <img src={logo} alt="logo" />
                            </Link>
                            <div className="head_block">
                                <img
                                    src={self_confidence}
                                    alt="self_confidence"
                                />
                                <span>Уверенность в себе</span>
                            </div>
                        </div>
                    </Fade>
                    <div className="content">
                        <Fade delay={600}>
                            <div className="title">
                                Пройди колесо отношений. Ответь на несколько
                                вопросов.
                            </div>
                        </Fade>
                        <Fade delay={900}>
                            <div className="descriptions">
                                8. Насколько ты уверена в себе и в своей
                                внешности - выбери от 1 до 10
                            </div>
                        </Fade>
                        <Fade delay={1200}>
                            <div className="range">
                                <Slider
                                    min={1}
                                    max={10}
                                    step={1}
                                    tooltip={true}
                                    value={value}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </Fade>
                        <Fade delay={1500}>
                            <div
                                onClick={() => {
                                    localStorage.setItem("eighth", value);
                                    this.setState({ loading: true });
                                    setTimeout(() => {
                                        history.push("/main/chart");
                                    }, 2000);
                                }}
                                className="btn"
                            >
                                Далее
                            </div>
                        </Fade>
                    </div>
                </div>
                {loading && <LoaderPage />}
            </TransitionedBlock>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Eighth);
