import { APP } from "../constants";

const INITIAL_STATE = {
    headerInfo: {},
    colorTheme: "",
    loading: false,
    errorSnack: false,
    errorSnackText: "",
    successSnack: false,
    successSnackText: ""
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        default:
            return state;
    }
}
