import { CONSTS } from "../constants";

export function postStep(data) {
    return {
        type: CONSTS.POST_STEP,
        payload: {
            client: "default",
            request: {
                url: `/save-result/`,
                method: "post",
                data,
            },
        },
    };
}
