import React from "react";
import "./RenderField.scss";

const RenderField = ({
    input,
    placeholder,
    type,
    id,
    meta: { touched, error, warning },
    max,
    min,
    disabled = false,
}) => {
    return (
        <label
            className={
                touched && error
                    ? input.value !== ""
                        ? "block-input error_border value"
                        : "error_border block-input"
                    : input.value !== ""
                    ? "block-input value"
                    : "block-input"
            }
        >
            <input
                {...input}
                disabled={disabled}
                onKeyDown={(e) =>
                    e.keyCode === 13 ? e.preventDefault() : false
                }
                placeholder={placeholder}
                max={max}
                min={min}
                type={type}
                autoComplete="off"
            />
            <span className="error">
                {error !== undefined
                    ? typeof error === "string"
                        ? error
                        : error.map((el, id) => <p key={id}>{el}</p>)
                    : null}
            </span>
        </label>
    );
};

export default RenderField;
