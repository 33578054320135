import React, { Component, Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import First from "../../components/Steps/First";
import Second from "../../components/Steps/Second";
import Third from "../../components/Steps/Third";
import Fourth from "../../components/Steps/Fourth";
import Fifth from "../../components/Steps/Fifth";
import Sixth from "../../components/Steps/Sixth";
import Seventh from "../../components/Steps/Seventh";
import Eighth from "../../components/Steps/Eighth";
import MailPage from "../../components/MailPage/MailPage";
import ChartPage from "../../components/ChartPage/ChartPage";

class Container extends Component {
    render() {
        const { match } = this.props;

        return (
            <Fragment>
                <div className="page">
                    <div className="wrapper">
                        <Switch>
                            <Route
                                path={match.url}
                                exact
                                render={() => (
                                    <Redirect to="/main/first-step" />
                                )}
                            />
                            <Route
                                path={`${match.url}/first-step`}
                                exact
                                component={First}
                            />
                            <Route
                                path={`${match.url}/second-step`}
                                exact
                                component={Second}
                            />
                            <Route
                                path={`${match.url}/third-step`}
                                exact
                                component={Third}
                            />
                            <Route
                                path={`${match.url}/fourth-step`}
                                exact
                                component={Fourth}
                            />
                            <Route
                                path={`${match.url}/fifth-step`}
                                exact
                                component={Fifth}
                            />
                            <Route
                                path={`${match.url}/sixth-step`}
                                exact
                                component={Sixth}
                            />
                            <Route
                                path={`${match.url}/seventh-step`}
                                exact
                                component={Seventh}
                            />
                            <Route
                                path={`${match.url}/eighth-step`}
                                exact
                                component={Eighth}
                            />
                            {/* <Route path={`${match.url}/mail`} exact component={MailPage} /> */}
                            <Route
                                path={`${match.url}/chart`}
                                exact
                                component={ChartPage}
                            />
                            <Route render={() => <p>Not found</p>} />
                        </Switch>
                    </div>
                    <div className="bg"></div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = () => {
    return {};
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
